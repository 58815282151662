import { createTheme } from "@mui/material/styles";
import { teal as green, lightBlue as blue } from "@mui/material/colors";

function applyResponsiveFont(theme) {
  theme.typography.headline = {
    ...theme.typography.headline,
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  };
  return theme;
}

const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 680, // make it easier to handle small size
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["Atkinson Hyperlegible", "sans-serif"].join(","),
    button: {
      textTransform: "none",
      fontWeight: 700,
    },
    headline: {
      fontWeight: 700,
      fontSize: "1.875rem",
      lineHeight: 1.2,
    },
    blockquote: {
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    h1: { fontWeight: 400, lineHeight: 1.4 },
    h2: { fontWeight: 400, lineHeight: 1.4 },
    h3: { fontWeight: 500, lineHeight: 1.3 },
    h4: { fontWeight: 500, lineHeight: 1.3 },
    h5: { fontWeight: 700, lineHeight: 1.2 },
    h6: { fontWeight: 700, lineHeight: 1.2 },
    overline: {
      fontWeight: 700,
      letterSpacing: "0.2em",
      textTransform: "uppercase",
      lineHeight: 1.2,
    },
    subtitle: {
      lineHeight: 1.4,
    },
  },
  palette: {
    green,
    warningInfo: {
      light: "#FBE77C",
    },
    secondary: {
      light: green[700],
      main: green[800],
      dark: green[900],
      contrastText: "#fff",
    },
    primary: {
      light: blue[400],
      main: blue[700],
      dark: blue[800],
      contrastText: "#fff",
    },
  },
};

const theme = applyResponsiveFont(createTheme(baseTheme));

export const darkTheme = applyResponsiveFont(
  createTheme({
    ...baseTheme,
    ...{
      palette: {
        ...baseTheme.palette,
        mode: "dark",
        background: {
          paper: green[700],
          default: green[900],
        },
        secondary: {
          light: green[50],
          main: green[200],
          dark: green[400],
          contrastText: "rgba(0, 0, 0, 0.87)",
        },
        primary: {
          main: "#fff",
          light: "#fff",
          dark: "#fff",
          contrastText: green[900],
        },
      },
    },
  })
);

// add `theme` to window for local debugging purposes
if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
  window.theme = theme;

  window.darkTheme = darkTheme;
}

export default theme;
